import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import PlayerArea from './PlayerArea';
import Logo from '../Logo';
import TransitionContainer from '../TransitionContainer';
import colours from "../../constants/colour";


const QuestionBodyComponent = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    min-height: 640px;
`;

const ContentPanelComponent = styled.div`
    height: 100%;
    flex: 1;
    display: flex;

    background-image: url("${process.env.PUBLIC_URL}/img/question/question-border.jpg");
    background-repeat: repeat-y;
    background-position: right;
    background-size: contain;
`;

const ContentPanelInner = styled.div`
    height: 100%;
    overflow: auto;
    flex-grow: 1;

    display: flex;
    flex-direction: column;
    align-items: center;

    text-align: center;

    margin-right: 40px;
    padding: 0;
    
    background-color: #FAF6E6;
`;

const LogoComponent = styled(Logo)`
    padding-top: 15px;
    width: 100px;
    height: auto;
`;

const BottomLinkContainer = styled.div`
    margin: 5px 0 20px;

    a {
        text-decoration: none;
        color: #7B5D4E;
        font-family: 'dumbledor__kernedregular';
        &:hover {
            color: ${colours.primary};
        }
    }
`;


export default function QuestionPageLayout({ children, playersData, bottomLinkLabel, bottomLinkTo }) {
    return (
        <TransitionContainer>
            <QuestionBodyComponent>
                <PlayerArea playersData={playersData} pixelWidth={1024} />
                <ContentPanelComponent>
                    <ContentPanelInner>
                        <LogoComponent />
                        {children}
                        <BottomLinkContainer>
                            <Link to={bottomLinkTo}>{bottomLinkLabel}</Link>
                        </BottomLinkContainer>
                    </ContentPanelInner>
                </ContentPanelComponent>
            </QuestionBodyComponent>
        </TransitionContainer>
    );
}
