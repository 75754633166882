import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import TransitionContainer from './TransitionContainer';

const InitialPageLoadScreenComponent = styled.div`
    background-color: black;
    color: white;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const LoadingTextComponent = styled.h1`
    margin-left: 10px;
`;

const TransitionContainerComponent = styled(TransitionContainer)`
    height: 100%;
`;

export default function InitialPageLoadScreen () {
    return (
        <TransitionContainerComponent>
            <InitialPageLoadScreenComponent>
                <FontAwesomeIcon icon={faCircleNotch} size="lg" spin />
                <LoadingTextComponent>Loading...</LoadingTextComponent>
            </InitialPageLoadScreenComponent>
        </TransitionContainerComponent>
    );
}
