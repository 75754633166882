import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
import { AnimatePresence } from 'framer-motion';

import { getQuizData } from './utils';
import Header from './components/Header';
import Question from './components/Question';
import QuizResults from './components/QuizResults';
import QuizSelect from './components/QuizSelect';
import QuizHome from './components/QuizHome';
import InitialPageLoadScreen from './components/InitialPageLoadScreen';


const GlobalStyle = createGlobalStyle`
  body {
    background-image: url("${process.env.PUBLIC_URL}/img/global/background-img2.webp");
  }
`;

const AppComponent = styled.div`
    min-height: 650px;
    height: 100%;
    width: 100%;
    min-width: 1440px;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  `;

  const InnerContainer = styled.div`
    background-color: black;
    width: 1440px;
    max-height: 910px;
    height: 100%;

    display: flex;
    flex-direction: column;
`;

export default class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isReady: false,
    };
  }

  async componentDidMount() {
    await getQuizData();
    this.setState({ isReady: true });
  }

  render() {
    if (!this.state.isReady) return <InitialPageLoadScreen />;

    return (
      <Router basename={'/'}>
        <GlobalStyle />
        <AppComponent>
          <InnerContainer>
            <Header />
            <AnimatePresence>
              <Switch>
                <Route path="/select">
                  <QuizSelect />
                </Route>
                <Route path="/:quizID/question-:questionNum">
                  <Question />
                </Route>
                <Route path="/:quizID/results">
                  <QuizResults />
                </Route>
                <Redirect from="/:quizID" to="/:quizID/question-1" />
                <Route path="/">
                  <QuizHome />
                </Route>
              </Switch>
            </AnimatePresence>
          </InnerContainer>
        </AppComponent>
      </Router>
    );
  }
}
