import React from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import {
  getAllQuestionsData,
  getQuizAllAnswers,
  getFirstUnansweredQuestionIndex,
  getQuizResultText,
} from '../../utils';
import imagePaths from '../../constants/image-paths';
import QuestionPageLayout from '../Question/QuestionPageLayout';


const QuizResultsComponent = styled.div`
  position: relative;
  height: 100%;
`;

const InnerContainer = styled.div`
  flex-grow: 1;
  display: flex;  
  flex-direction: column;
  align-items: center;
  gap: 30px;
  padding: 10px 20px 20px;
  color: #5C1F22;
`;

const SubTitle = styled.h2`
  text-align: center;
  margin: 0 10px;

  font-family: 'dumbledor__kernedregular';
  font-size: 180%;
`;

const ResultTextContainer = styled.div`
  margin: 0;
  font-family: 'Trade Gothic LT Std';
  font-style: italic;
`;

const ResultText = styled.h3`
  font-weight: normal;
  margin: 2px 0;
`;

const TokenContainer = styled.div`
  position: relative;
  width: 194px;
  height: 194px;
`;

const TokenComponent = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;

const TokenShadow = styled.div`
    position: absolute;
    background-size: cover;
    justify-content: center;
    align-items: center;
    opacity: 0.5;

    background-image: url("${process.env.PUBLIC_URL}/img/global/circle-shadow.webp");
    width: 206px;
    height: 206px;
`;

const ResultImage = styled.div`
  position: absolute;
  left: 0;
  right: 460px;
  top: 0;
  bottom: 0;

  background-image: url('${imagePaths.backgrounds}/${props => props.bgFilename}');
  background-size: cover;
  background-position: top right;
`;

class QuizResults extends React.Component {
  componentDidMount() {
    const firstUnansweredQuestionIndex = getFirstUnansweredQuestionIndex(this.props.match.params.quizID);
    if (firstUnansweredQuestionIndex > -1) {
      this.props.history.push(`/${this.props.match.params.quizID}/question-${firstUnansweredQuestionIndex + 1}`);
    }

    // this is for the facebook share button -- without this, it has issue where the button only shows after the page is refreshed
    if (window.FB) {
      window.FB.XFBML.parse();
    }
  }

  render() {
    let { quizID } = this.props.match.params;

    const allAnswersList = getQuizAllAnswers(quizID);

    let correctAnswerCount = 0;

    getAllQuestionsData(quizID).forEach(
      (questionData, i) => {
        if (questionData.correctAnswer === allAnswersList[i]) correctAnswerCount++;
      }
    );


    const { resultText, resultImageFilename, bgFilename } = getQuizResultText(quizID, correctAnswerCount);

    return (
      <QuizResultsComponent>
        <QuestionPageLayout playersData={[]} bottomLinkLabel="Try another quiz" bottomLinkTo="/select">
          <InnerContainer>
            <SubTitle>You scored<br/>{correctAnswerCount}/{allAnswersList.length}</SubTitle>
            <ResultTextContainer>
                <ResultText>{resultText}</ResultText>
            </ResultTextContainer>
            <TokenContainer>
              <TokenShadow />
              <TokenComponent alt={resultImageFilename} src={`${imagePaths.characterTokens}/${resultImageFilename}`} />
            </TokenContainer>
          </InnerContainer>
        </QuestionPageLayout>
        <ResultImage bgFilename={bgFilename} />
      </QuizResultsComponent>
    );
  }
}

export default withRouter(QuizResults);
