import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import styled, { css } from 'styled-components';
import colour from '../../constants/colour';

const AnswerOptionComponent = styled.div`
    list-style-type: none;
    margin: 4px;
    position: relative;
    border: solid 2px #BDA89A;

    ${props => {
        if (props.isAnswered && props.isCorrect && !props.isSelected)
            return css`border-color: ${colour.success};`;
        else if (props.isAnswered && props.isCorrect)
            return css`background-color: ${colour.success}; border-color: ${colour.success}; color: white;`;
        else if (props.isAnswered && !props.isCorrect && props.isSelected)
            return css`background-color: ${colour.danger}; border-color: ${colour.danger}; color: white; padding-right: 32px;`;
        else if (props.isAnswered)
            return css`color: #BDA89A;`;
        else 
            return css`&:hover { 
                background-color: #BDA89A; 
                color: white;
            }`;
    }}
`;

const InputComponent = styled.input`
    display: none;
`;

const LabelComponent = styled.label`
    padding: 10px 15px;
    height: 100%;
    cursor: ${props => props.isAnswered ? 'default' : 'pointer'};
    display: block;
`;

const AnswerResultMarkerContainer = styled.span`
    position: absolute;
    right: 15px;
    top: 10px;
    font-weight: bold;
`;

export default function AnswerOption({ answerIndex, answerText, isAnswered, isSelected, isCorrect, handleAnswerSelect }) {
    let answerResultMarker;

    if (isAnswered && isSelected) {
        answerResultMarker = isCorrect ? null : <FontAwesomeIcon icon={faTimes} />;
    }

    return (
        <AnswerOptionComponent isCorrect={isCorrect} isAnswered={isAnswered} isSelected={isSelected}>
            <InputComponent
                disabled={isAnswered ? "disabled" : ""}
                onChange={handleAnswerSelect}
                checked={isSelected}
                type="radio"
                id={`item-${answerIndex}`}
            />
            <AnswerResultMarkerContainer isCorrect={isCorrect}>{answerResultMarker}</AnswerResultMarkerContainer>
            <LabelComponent isAnswered={isAnswered} htmlFor={`item-${answerIndex}`}>{answerText}</LabelComponent>
        </AnswerOptionComponent>
    );
}
