import React from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';

const pageVariants = {
    initial: {
        opacity: 0,
    },
    in: {
        opacity: 1,
    },
    out: {
        opacity: 0,
    }
};

const pageTransition = {
    type: "tween",
    ease: "anticipate",
    duration: 0.5
};

const TransitionContainerComponent = styled(motion.div)`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    height: 100%;
`;

export default function TransitionContainer({ className, children }) {
    return (
        <TransitionContainerComponent
            initial="initial"
            animate="in"
            exit="out"
            variants={pageVariants}
            transition={pageTransition}
            className={className}
        >{children}</TransitionContainerComponent>
    );
}
