import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import colour from '../constants/colour';


const MainComponent = styled(Link)`
    display: inline-flex;
    flex-direction: row;
    text-align: center;
    align-items: center;
    text-decoration: none;

    ${props => css`
        opacity: ${props.$isEnabled ? 1 : 0.2};
    `};
`;

const DecorationRight = styled.img`
    transform: rotate(180deg);
`;

const Inner = styled.span`
    text-transform: uppercase;
    padding: 0.8rem 4rem;
    font-family: 'Trade Gothic LT Std';

    ${props => css`
        border: solid 2px ${props.isLightMode ? '#BDA89A' : colour.fancyButt};
        color: ${props.isLightMode ? '#7B5D4E' : colour.fancyButt};
    `};
  
    ${props => {
      if (props.$isEnabled) return css`
        &:hover {
          background-color: ${props.isLightMode ? '#BDA89A' : colour.fancyButt};
          color: ${props.isLightMode ? 'white' : colour.primary};
        }
      `;
    }}
`;

function Main({ label, isEnabled, isLightMode, ...props }){
    return (
        <MainComponent {...props} $isEnabled={isEnabled}>
            <img role="presentation" src="/img/global/button-decoration.webp" height="28" alt="" />
            <Inner isLightMode={isLightMode} $isEnabled={isEnabled}>
                {label}
            </Inner>
            <DecorationRight role="presentation" src="/img/global/button-decoration.webp" height="28" alt="" />
        </MainComponent>
    )
}

export default function FancyButton({ toPath, href, label, isLightMode, isEnabled = true }) {
    if (toPath) {
        return <Main label={label} to={toPath} isLightMode={isLightMode} isEnabled={isEnabled} />
    }

    return <Main label={label} href={href} isLightMode={isLightMode} isEnabled={isEnabled} />
}
