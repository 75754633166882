import React from 'react';
import {
    Link,
} from 'react-router-dom';
import styled, { css } from 'styled-components';
import colours from "../../constants/colour";


const QuizLinksComponent = styled.ul`
    display: flex;
    width: 100%;
    margin: 0 0 80px;
    padding: 0;
    flex-wrap: wrap;
    justify-content: center;
    gap: 100px;

    font-family: 'dumbledor__kernedregular';
`;

const LinkContainerComponent = styled.li`
    display: block;
    position: relative;

    width: 218px;
    height: 350px;
`;

const QuizCoverImageContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
`;

const QuizCoverImage = styled.img`
    width: 100%;
`;

const QuizGroup = styled.div`
    display: block;
    border-radius: 20px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    display: flex;
    flex-direction: column;
    
    background-color: #F9F7F1;
    text-decoration: none;
`;

const QuizGroupLabel = styled.div`
    background-color: #F1EBDC;
    color: #7B5D4E;
    padding: 20px;
`;

const QuizLinksContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    justify-content: space-around;
    padding: 10px 20px;
`;

const QuizLink = styled(Link)`
    display: block;
    width: 100%;
    color: #7B5D4E;
    font-size: 120%;
    text-decoration: none;
    padding: 5px 0;
    &:hover {
        color: ${colours.primary};
    }
`;

const InkBlotch = styled.div`
    ${props => css`
        background-image: url("${process.env.PUBLIC_URL}/img/ink-blotch/${props.imgName}");
    `};
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: -80px;
    bottom: -80px;
    left: -100px;
    right: -100px;
`;


const quizImageDataMap = {
    'Fundamentals': { coverImageSrc: '/img/quiz-cover/quiz-cover-default.webp', inkColour: 'gold' },
    'Trouble Brewing': { coverImageSrc: '/img/quiz-cover/quiz-cover-trouble-brewing.webp', inkColour: 'red' },
    'Sects & Violets': { coverImageSrc: '/img/quiz-cover/quiz-cover-sects-violet.webp', inkColour: 'purple' },
    'Bad Moon Rising': { coverImageSrc: '/img/quiz-cover/quiz-cover-bad-moon-rising.webp', inkColour: 'orange' },
}


export default function QuizLinks({ quizList }) {
    const groupedQuizList = {};

    for (let quizID in quizList) {
        const quizGroupName = quizList[quizID].groupName;
        const groupImageOverride = quizList[quizID].groupImageOverride;
        const groupInkOverride = quizList[quizID].groupInkOverride;
        const quizLabelSubtitle = quizList[quizID].difficulty;

        if (!groupedQuizList[quizGroupName]) {
            let coverImageData;

            if (groupImageOverride) {
                coverImageData = {
                    coverImageSrc: `/data/custom_images/${groupImageOverride}`,
                    inkColour: groupInkOverride,
                }
            }
            else {
                coverImageData = quizGroupName in quizImageDataMap ? quizImageDataMap[quizGroupName] : quizImageDataMap['Fundamentals'];
            }

            groupedQuizList[quizGroupName] = {
                coverImageData: coverImageData,
                label: quizGroupName,
                quizzes: {}
            };
        }

        groupedQuizList[quizGroupName].quizzes[quizID] = quizLabelSubtitle;
    }

    return (
        <QuizLinksComponent>
            {
                Object.keys(groupedQuizList).map(quizLabel => {
                    const quizLinks = [];
                    const quizIDs = Object.keys(groupedQuizList[quizLabel].quizzes);

                    for (let i = 0; i < quizIDs.length; i++) {
                        quizLinks.push(
                            <QuizLink key={quizIDs[i]} to={`/${quizIDs[i]}`}>
                                {groupedQuizList[quizLabel].quizzes[quizIDs[i]]}
                            </QuizLink>
                        );
                    }

                    return (
                        <LinkContainerComponent key={quizLabel}>
                            <InkBlotch imgName={`ink-blotch-${groupedQuizList[quizLabel].coverImageData.inkColour}-top.webp`} />
                            <InkBlotch imgName={`ink-blotch-${groupedQuizList[quizLabel].coverImageData.inkColour}-bottom.webp`} />

                            <QuizGroup>
                                <QuizCoverImageContainer>
                                    <QuizCoverImage src={groupedQuizList[quizLabel].coverImageData.coverImageSrc} alt={`${quizLabel} logo`} />
                                </QuizCoverImageContainer>
                                <QuizGroupLabel>{groupedQuizList[quizLabel].label}</QuizGroupLabel>
                                <QuizLinksContainer>
                                    {quizLinks}
                                </QuizLinksContainer>
                            </QuizGroup>
                        </LinkContainerComponent>
                    );
                })
            }
        </QuizLinksComponent>
    );
}
