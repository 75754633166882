// returns a point (x, y) along the path of a circle as a percentage -- e.g. a point of (50, 50) refers to a point
//at the center of it's container -- i.e. bottom: 50%, left: 50%
export function createPointAlongCircle(angle, radiusAsPercentage) {
    const x1 = 0;
    const y1 = radiusAsPercentage;
    
    const angleAsRad = angle * (Math.PI / 180);

    return {
        x: (x1 * Math.cos(angleAsRad) - y1 * Math.sin(angleAsRad)) + 50, // note: 50 is for the center (i.e. 50%)
        y: (x1 * Math.sin(angleAsRad) + y1 * Math.cos(angleAsRad)) + 50,
    };
}

// returns a hardcoded token position for certain token counts -- mainly for the higher token counts which
// needs some manual adjustments for better positioning.
export function getHardcodedTokenPosition(tokenIndex, tokenCount) {
    switch (tokenCount) {
        case 9:
            switch (tokenIndex) {
                case 0: return {x: 50, y: 86};
                case 1: return {x: 29, y: 80};
                case 2: return {x: 15, y: 56};
                case 3: return {x: 21, y: 30};
                case 4: return {x: 38, y: 16};
                case 5: return {x: 62, y: 16};
                case 6: return {x: 79, y: 30};
                case 7: return {x: 85, y: 56};
                case 8: return {x: 71, y: 80};
                default: break;
            }
            break;
        case 10:
            switch (tokenIndex) {
                case 0: return {x: 50, y: 86};
                case 1: return {x: 30, y: 80};
                case 2: return {x: 16, y: 63};
                case 3: return {x: 16, y: 37};
                case 4: return {x: 30, y: 20};
                case 5: return {x: 50, y: 14};
                case 6: return {x: 70, y: 20};
                case 7: return {x: 84, y: 37};
                case 8: return {x: 84, y: 63};
                case 9: return {x: 70, y: 80};
                default: break;
            }
            break;
        case 11:
            switch (tokenIndex) {
                case 0: return {x: 50, y: 86};
                case 1: return {x: 32, y: 81};
                case 2: return {x: 18, y: 67};
                case 3: return {x: 15, y: 43};
                case 4: return {x: 25, y: 23};
                case 5: return {x: 41, y: 15};
                case 6: return {x: 59, y: 15};
                case 7: return {x: 75, y: 23};
                case 8: return {x: 85, y: 43};
                case 9: return {x: 82, y: 67};
                case 10: return {x: 68, y: 81};
                default: break;
            }
            break;
        case 12:
            switch (tokenIndex) {
                case 0: return {x: 50, y: 86};
                case 1: return {x: 34, y: 82};
                case 2: return {x: 20, y: 72};
                case 3: return {x: 14, y: 50};
                case 4: return {x: 20, y: 28};
                case 5: return {x: 34, y: 18};
                case 6: return {x: 50, y: 14};
                case 7: return {x: 66, y: 18};
                case 8: return {x: 80, y: 28};
                case 9: return {x: 86, y: 50};
                case 10: return {x: 80, y: 72};
                case 11: return {x: 66, y: 82};
                default: break;
            }
            break;
        default: break;
    }

    return null;
}
