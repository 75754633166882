import React from 'react';
import { withRouter } from 'react-router-dom';
import styled, { css } from 'styled-components';
import PlayerToken from './PlayerToken';

const PlayAreaComponent = styled.div`
    position: relative;
    background-image: url("${process.env.PUBLIC_URL}/img/question/grimoire-bg_sqaure 1.jpg");
    background-size: 100% 100%;

    ${props => css`
        width: ${props.pixelWidth}px;
        height: 100%;
    `};
`;

const FabledContainer = styled.div`
    position: absolute;
    display: flex;
    top: 20px;
    right: 20px;
    height: 138px;

    ${props => css`
        width: ${props.isExpanded && props.fabledTokenCount > 1 ? props.fabledTokenCount * 130 : '180'}px;
    `};

    transition: width 0.3s ease;
`;

const FabledMouseOverElement = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
`;

export class PlayerArea extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isFabledMouseOver: false,
        };
    }

    handleFabledTokenMouseEnter = () => {
        this.setState({ isFabledMouseOver: true });
    }
    handleFabledTokenMouseLeave = () => {
        this.setState({ isFabledMouseOver: false });
    }

    render() {
        let centerToken = null;
        let fabledTokens = [];

        this.props.playersData.forEach((playerData, i) => {
            if (playerData.name === 'Fabled') {
                fabledTokens.push(i);
            }
        });

        const filteredPlayerTokensData = this.props.playersData.filter((playerData, i) => {
            var fabledIndex = fabledTokens.indexOf(i);
            if (fabledIndex >= 0) {
                fabledTokens[fabledIndex] = (<PlayerToken
                    key={`fabledToken${fabledIndex}`}
                    tokenIndex={fabledIndex}
                    angle={0}
                    playerName={playerData.name}
                    tokenFilename={playerData.tokenFilename}
                    reminderToken1Filename={playerData.reminderToken1Filename}
                    reminderToken2Filename={playerData.reminderToken2Filename}
                    hasShroudToken={playerData.hasShroudToken}
                    isFabledToken={true}
                    tokenCount={fabledTokens.length}
                />);
                return false;
            }
            return true;
        });

        const filteredPlayerTokens = filteredPlayerTokensData.map((playerData, i) => {
            // tokens are placed around a circle, so "angle" defines angle between the top position and placement of token

            return <PlayerToken
                key={i}
                tokenIndex={i}
                tokenCount={filteredPlayerTokensData.length}
                playerName={playerData.name}
                tokenFilename={playerData.tokenFilename}
                reminderToken1Filename={playerData.reminderToken1Filename}
                reminderToken2Filename={playerData.reminderToken2Filename}
                hasShroudToken={playerData.hasShroudToken}
                isFlippedToken={playerData.isFlipped}
            />
        });

        return (
            <PlayAreaComponent pixelWidth={this.props.pixelWidth}>
                {filteredPlayerTokens}
                {centerToken}
                <FabledContainer
                    isExpanded={this.state.isFabledMouseOver}
                    fabledTokenCount={fabledTokens.length}
                    onMouseEnter={this.handleFabledTokenMouseEnter}
                    onMouseLeave={this.handleFabledTokenMouseLeave}
                >
                    <FabledMouseOverElement />
                    {fabledTokens}
                </FabledContainer>
            </PlayAreaComponent>
        );
    }
}

export default withRouter(PlayerArea);