import React from 'react';
import styled from 'styled-components';
import TransitionContainer from '../TransitionContainer';
import FancyButton from '../FancyButton';
import Logo from '../Logo';


const HomeComponent = styled.div`
    width: 100%;
    height: 100%;
    
    text-align: center;
    color: white;

    background-image: url("${process.env.PUBLIC_URL}/img/global/bg_home.jpg");
    background-size: 1440px;
    background-repeat: repeat-y;
    background-position-y: bottom;
`;

const Inner = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    margin-top: 60px;
`;

const QuizTitleTextLogo = styled(Logo)`
    margin-bottom: 10px;
`

const Subtitle = styled.h2`
    font-family: 'dumbledor__kernedregular';
    filter: drop-shadow(3px 3px 4px #000000);
`;


export default function QuizHome() {
    return (
        <TransitionContainer>
            <HomeComponent>
                <Inner>
                    <img src="img/global/botc_head.webp" width="275" height="295" alt="Blood on the Clocktower 'head' logo" />
                    <QuizTitleTextLogo />
                    <Subtitle>Take the quiz and test your Blood on the clock tower knowledge</Subtitle>
                    <FancyButton toPath={"/select"} label={"Click to Start"} />
                </Inner>
            </HomeComponent>
        </TransitionContainer>
    );
}
