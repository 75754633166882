import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { createPointAlongCircle, getHardcodedTokenPosition } from './PlayerArea.utils';
import imagePaths from '../../constants/image-paths';

const OutterContainer = styled.div`
    display: flex;

    ${props => {
        if (props.isFabledToken) {
            return css`
                position: absolute;
                width: ${props.pixelWidth}px;
                height: ${props.pixelWidth}px;
                top: 0;
                left: ${props.left === 100 ? 'auto' : props.left + '%' };
                right: ${props.left === 100 ? '0' : 'auto' };
                margin-left: ${props.left > 0 && props.left < 100 ? '-70px' : '0' };
            `;
        }

        return css`
            position: absolute;
            width: ${props.pixelWidth}px;
            height: ${props.pixelWidth}px;
            bottom: ${props.bottom}%;
            left: ${props.left}%;
            margin-left: -${props.pixelWidth / 2}px;
            margin-bottom: -${(props.pixelWidth / 2) + props.tokenYOffset}px;
        `;
    }}
`;

const TokenComponent = styled.div`
    position: absolute;
    background-size: cover;
    justify-content: center;
    align-items: center;

    transition: transform .2s; /* Animation */
    
    ${props => props.shouldZoom && css`
        transform: scale(1.6);
        z-index: 2;
    `}

    ${props => props.isFlipped && css`
        transform: rotate(180deg);
    `}

    ${props => css`
        display: ${props.isDisplayed ? 'flex' : 'none'};
        background-image: url("${props.tokenFilePath}");
        background-size: contain;
        width: ${props.pixelWidth}px;
        height: ${props.pixelWidth}px;
        bottom: ${props.bottom}%;
        left: ${props.left}%;
        margin-left: -${props.pixelWidth / 2}px;
        margin-bottom: -${(props.pixelWidth / 2) + props.tokenYOffset}px;
    `}
`;

const TokenShadow = styled.div`
    position: absolute;
    background-size: cover;
    justify-content: center;
    align-items: center;
    opacity: 0.3;

    ${props => css`
        display: ${props.isDisplayed ? 'block' : 'none'};
        background-image: url("${process.env.PUBLIC_URL}/img/global/circle-shadow.webp");
        width: ${props.pixelWidth}px;
        height: ${props.pixelWidth}px;
        bottom: ${props.bottom}%;
        left: ${props.left}%;
        margin-left: -${props.pixelWidth / 2}px;
        margin-bottom: -${(props.pixelWidth / 2) + props.tokenYOffset}px;
    `}
`;

const PlayerTokenName = styled.div`
    font-family: sans-serif;
    color: #ffffff;
    font-weight: bold;
    padding: 10px 0;
    position: absolute;
    bottom: -34px;
    width: 100%;
    text-align: center;

    filter: drop-shadow(3px 3px 4px #000000);
`;

const ShroudComponent = styled.div`
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top center;
    background-image: url("${process.env.PUBLIC_URL}/img/global/shroud.webp");
    width: 55px;
    height: 77px;
    top: 0;
    left: 50%;
    margin-left: -27px;
    margin-bottom: -27px;
`;

function generateFabledXPositions(count) {
    if (count <= 0) return [];

    const step = 100 / (count - 1);
    const result = [];

    for (let i = 0; i < count; i++) {
        result.push(i * step);
    }

    return result;
}

export default function PlayerToken({
    tokenIndex,
    tokenCount,
    playerName,
    tokenFilename,
    reminderToken1Filename,
    reminderToken2Filename,
    hasShroudToken,
    isFabledToken,
    isFlippedToken,
}) {
    const [shouldZoom, setShouldZoom] = useState(false);

    const tokenPixelWidth = 138;

    const angle = (360 / tokenCount) * tokenIndex;
    const radius = 36;

    // below gets the token position -- for higher token counts, they are manually set for a better layout
    // and for lower counts (and unexpected high counts) they are arranged mathematically.
    let tokenPosition = null;

    let shouldHideName = false;

    if (isFabledToken) {
        tokenPosition = { x: generateFabledXPositions(tokenCount)[tokenIndex], y: 0 };

        shouldHideName = tokenIndex < tokenCount - 1;
    }
    else if (tokenCount >= 9) {
        tokenPosition = getHardcodedTokenPosition(tokenIndex, tokenCount);
    }
    if (!tokenPosition) {
        tokenPosition = createPointAlongCircle(angle, radius);
    }

    // increasing this value will push the tokens higher -- it's a fraction of the height of the token
    let yOffsetFraction = 0;

    if (tokenPosition.y > 50) {// i.e. is token y position above 50%
        yOffsetFraction = 0.1;
    }
    else if (Math.round(tokenPosition.y) === 50) { // i.e. is token y position AT 50% mark
        // if the token is already at the mid point, then don't shift it up as much.
        // without this, the vertical distribution looks uneven
        yOffsetFraction = 0.1;
    }

    const tokenYOffset = tokenPixelWidth * yOffsetFraction;

    const reminderToken1Pos = createPointAlongCircle(angle + 180, 88);
    const reminderToken2Pos = createPointAlongCircle(angle + 180, 140);

    return (
        <OutterContainer
            pixelWidth={tokenPixelWidth}
            bottom={tokenPosition.y}
            left={tokenPosition.x}
            tokenYOffset={-tokenYOffset}
            isFabledToken={isFabledToken}
        >
            <TokenShadow pixelWidth={tokenPixelWidth + 20} bottom={46} left={50} tokenYOffset={0}/>
            <TokenComponent
                pixelWidth={tokenPixelWidth}
                bottom={50}
                left={50}
                tokenYOffset={0}
                tokenFilePath={`${imagePaths.characterTokens}/${tokenFilename}`}
                isFabledToken={isFabledToken}
                shouldZoom={shouldZoom}
                isFlipped={!shouldZoom && isFlippedToken}
                isDisplayed={true}
                onMouseEnter={() => {
                    setShouldZoom(true);
                }}
                onMouseLeave={() => {
                    setShouldZoom(false);
                }}
            />

            {!shouldHideName && <PlayerTokenName>{playerName}</PlayerTokenName>}

            <TokenShadow pixelWidth={85} bottom={reminderToken1Pos.y - 3} left={reminderToken1Pos.x} tokenYOffset={tokenYOffset} isDisplayed={!!reminderToken1Filename}/>
            <TokenComponent
                pixelWidth={75}
                bottom={reminderToken1Pos.y}
                left={reminderToken1Pos.x}
                tokenYOffset={tokenYOffset}
                tokenFilePath={`${imagePaths.reminderTokens}/${reminderToken1Filename}`}
                isDisplayed={!!reminderToken1Filename}
            />

            <TokenShadow pixelWidth={85} bottom={reminderToken2Pos.y - 3} left={reminderToken2Pos.x} tokenYOffset={tokenYOffset} isDisplayed={!!reminderToken2Filename}/>
            <TokenComponent
                pixelWidth={75}
                bottom={reminderToken2Pos.y}
                left={reminderToken2Pos.x}
                tokenYOffset={tokenYOffset}
                tokenFilePath={`${imagePaths.reminderTokens}/${reminderToken2Filename}`}
                isDisplayed={!!reminderToken2Filename}
            />
            {hasShroudToken && <ShroudComponent />}
        </OutterContainer>
    );
}
