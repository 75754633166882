import FancyButton from '../FancyButton';


export default function QuestionNextLink ({ quizID, questionCount, questionNum, isEnabled, ...props }) {
    let toPath;
    let label;

    if (questionNum < questionCount)
    {
        toPath = isEnabled ? `/${quizID}/question-${questionNum + 1}` : '#';
        label = 'Next';
    }
    else {
        toPath = `/${quizID}/results`;
        label = 'Finish!'
    }

    return <FancyButton {...props} isEnabled={isEnabled} toPath={toPath} label={label} />
}
