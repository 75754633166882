import { GoogleSpreadsheet } from 'google-spreadsheet';
import urlSlug from 'url-slug';

// data gets cached for below time
const EXPIRY_HRS = 24;

// google spreadsheet constants
const GOOGLE_API_KEY = 'AIzaSyCgpqeKfxqE0v5KsPhSnfdoQIK2-65nJIo';
const DOC_ID = '1rD6-2Y22L1Xbke2Kcska_bp1B7D84nXF7yy_l8Nk3Sg';

// local storage keys
const KEY_LAST_CHECK_TIME = 'LAST_CHECK_TIME';
const KEY_QUIZ_DATA = 'QUIZ_DATA';

export async function fetchQuizData() {
    const lastCheckTime = localStorage.getItem(KEY_LAST_CHECK_TIME);
    const cachedData = localStorage.getItem(KEY_QUIZ_DATA);
    const currentTime = Date.now();

    let data;

    if (!lastCheckTime || !cachedData || (currentTime - lastCheckTime) > (EXPIRY_HRS * 3600000)) {
        const doc = new GoogleSpreadsheet(DOC_ID, { apiKey: GOOGLE_API_KEY });

        await doc.loadInfo();

        data = await getMergedWorksheets(doc);
        localStorage.setItem(KEY_QUIZ_DATA, JSON.stringify(data));
        localStorage.setItem(KEY_LAST_CHECK_TIME, currentTime);
    }
    else {
        data = JSON.parse(cachedData);
    }

    return data;
}

function isQuizLive(tabColour) {
    return tabColour && tabColour.green === 1 && !tabColour.blue && !tabColour.red;
}

function isQuizTest(tabColour) {
    return tabColour && tabColour.red === 1 && tabColour.blue === 1 && !tabColour.green;
}

async function getMergedWorksheets(doc) {
    const workSheets = doc.sheetsByIndex;
    let mergedWorksheetsRows = [];
    const promises = [];

    const isTestSite =
        // seems safer to test for non production host names rather then confirming the production host name.
        // e.g. if confirming that the site is the production, and the host name of the production quiz site
        // happens to change, it would mean the test quizzes would be revealed.
        // note: during testing can use ip which will show production quizzes
        window.location.host.indexOf('localhost') > -1
        || window.location.host.indexOf('quiz-test') === 0
        || window.location.host.indexOf('quiz-dev') === 0
    ;

    for (let i = 0; i < workSheets.length; i++)
    {
        if ((isTestSite && isQuizTest(workSheets[i].tabColor)) || (!isTestSite && isQuizLive(workSheets[i].tabColor))) {
            promises.push(workSheets[i].getRows());
        }
    }

    const values = await Promise.all(promises);

    for (let i = 0; i < values.length; i++)
    {
        mergedWorksheetsRows = mergedWorksheetsRows.concat(values[i]);
    }

    const csvConversion = mergedWorksheetsRows.map(
        googleSpreadsheetRow => Object.assign(['', '', '', '', '', ''], googleSpreadsheetRow._rawData)
    );

    const json = doConversion(csvConversion);

    return json;
};


const FieldID = Object.freeze({
    'quizName': { name: 'Quiz Group Name', fieldNameIndex: 0, nameIndex: 1 },
    'quizDifficulty': { name: 'Quiz Difficulty', fieldNameIndex: 0, nameIndex: 1 },
    'quizImageFilename': { name: 'Quiz Image', fieldNameIndex: 0, valueIndex: 1 },
    'groupImageOverride': { name: 'Group Image Override', fieldNameIndex: 0, valueIndex: 1 },
    'groupInkOverride': { name: 'Group Ink Override', fieldNameIndex: 0, valueIndex: 1 },
    'completionText': {
        name: 'Text to show at completion',
        fieldNameIndex: 1,
        textIndex: 1,
        tokenFilenameIndex: 2,
        bgFilenameIndex: 3,
    },
    'questionPrefix': { name: 'Question ', fieldNameIndex: 0, isPrefix: true, questionIndex: 1 },
    'playerName': {
        name: 'Player name',
        fieldNameIndex: 1,
        nameIndex: 1,
        tokenFilenameIndex: 2,
        reminderToken1Filename: 3,
        reminderToken2Filename: 4,
        isShroud: 5,
        isFlipped: 6,
    },
    'answersHeader': { name: 'Answers', fieldNameIndex: 0 },
    'answerPrefix': { name: 'Answer ', fieldNameIndex: 0, isPrefix: true, answerValueIndex: 1 },
    'correctAnswer': { name: 'Correct Answer', fieldNameIndex: 0, answerIndex: 1 },
    'answerReasonText': { name: 'Answer Text', fieldNameIndex: 0 },
});


function doesMatchField(row, fieldID)
{
    if (FieldID[fieldID].isPrefix)
        return row[FieldID[fieldID].fieldNameIndex].indexOf(FieldID[fieldID].name) === 0;

    return row[FieldID[fieldID].fieldNameIndex] === FieldID[fieldID].name;
}

function doConversion(records)  {
    const exportData = { quizzes: {} };
    let currentQuizID;
    let currentQuizData;

    let i = 0;

    while (i < records.length) {
        const row = records[i];

        if (doesMatchField(row, 'quizName')) {
            const quizName = row[FieldID.quizName.nameIndex];
            currentQuizData = { groupName: quizName, questions: [] };
        }
        else if (doesMatchField(row, 'quizDifficulty')) {
            const quizDifficulty = row[FieldID.quizDifficulty.nameIndex];
            currentQuizData.difficulty = quizDifficulty;
            currentQuizData.name = `${currentQuizData.groupName} - ${quizDifficulty}`;

            currentQuizID = urlSlug(currentQuizData.name);
            exportData.quizzes[currentQuizID] = currentQuizData;
        }
        else if (doesMatchField(row, 'groupImageOverride')) {
            currentQuizData['groupImageOverride'] = row[FieldID.groupImageOverride.valueIndex];
        }
        else if (doesMatchField(row, 'groupInkOverride')) {
            currentQuizData['groupInkOverride'] = row[FieldID.groupInkOverride.valueIndex];
        }
        else if (doesMatchField(row, 'completionText')) {
            const resultTextList = [];
            currentQuizData['resultText'] = resultTextList;

            let completionText = '';

            do {
                i++;
                completionText = records[i][FieldID.completionText.textIndex];

                if (completionText) {
                    resultTextList.push({
                        text: completionText,
                        imagePath: records[i][FieldID.completionText.tokenFilenameIndex],
                        bgFilename: records[i][FieldID.completionText.bgFilenameIndex]
                    });
                }
            } while(completionText);

            // NOTE: it's assumed that after the list of completion texts, there is a blank row
        }
        else if (doesMatchField(row, 'questionPrefix') && row[FieldID.questionPrefix.questionIndex]) {
            let currentQuestion = {
                question: records[i][FieldID.questionPrefix.questionIndex],
                players: [],
                answerOptions: [],
            };
            currentQuizData['questions'].push(currentQuestion);


            let isCompleted = false;

            let isLookingForPlayerData = false;

            do {
                i++;

                // if found an empty row, assume that's the end of the current question data
                if (!records[i] || records[i].join('').length < 1) {
                    isCompleted = true;
                }
                else if (doesMatchField(records[i], 'playerName')) {
                    isLookingForPlayerData = true;
                }
                else if (doesMatchField(records[i], 'correctAnswer')) {
                    isLookingForPlayerData = false;
                    currentQuestion.correctAnswer = records[i][FieldID.correctAnswer.answerIndex] - 1;
                }
                else if (doesMatchField(records[i], 'answerReasonText')) {
                    isLookingForPlayerData = false;
                    currentQuestion.answerReason = records[i][FieldID.answerPrefix.answerValueIndex];

                    // this assumes that the answer reason text is the last part of the definition of a question
                    isCompleted = true;
                }
                // NOTE: answer option field (answerPrefix) should be checked AFTER answerReasonText, as doesMatchField() will
                // match for answerReasonText.
                else if (doesMatchField(records[i], 'answerPrefix') && records[i][FieldID.answerPrefix.answerValueIndex]) {
                    isLookingForPlayerData = false;
                    currentQuestion.answerOptions.push(records[i][FieldID.answerPrefix.answerValueIndex]);
                }
                else if (doesMatchField(records[i], 'answersHeader')) {
                    // this is here to catch the completion of the players details
                    isLookingForPlayerData = false;
                }
                else if (isLookingForPlayerData && records[i][FieldID.playerName.nameIndex]) {
                    currentQuestion.players.push({
                        name: records[i][FieldID.playerName.nameIndex],
                        tokenFilename: convertImageFilename(records[i][FieldID.playerName.tokenFilenameIndex]),
                        reminderToken1Filename: convertImageFilename(records[i][FieldID.playerName.reminderToken1Filename]),
                        reminderToken2Filename: convertImageFilename(records[i][FieldID.playerName.reminderToken2Filename]),
                        hasShroudToken: records[i][FieldID.playerName.isShroud] === 'TRUE',
                        isFlipped: records[i][FieldID.playerName.isFlipped] === 'TRUE',
                    });
                }

            } while(!isCompleted);
        }

        i++;
    }

    return exportData;
}

// converts image filename data from the spreadsheet to actual filename
function convertImageFilename(inputValue) {
    const trimmed = inputValue.trim();
    return trimmed ? trimmed.replace(' ', '_').toLowerCase() + '.webp' : '';
}
