import React from 'react';
import styled from 'styled-components';

const LogoComponent = styled.img`
    
`;

export default function Logo({ className }) {
    return (
        <LogoComponent className={className} src={`${process.env.PUBLIC_URL}/img/global/quiz_title_text.webp`} width="167" height="127" alt="Quiz title text" />
    );
}
