import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import colour from '../constants/colour';
import { getQuizName } from '../utils';


const Main = styled.div`
    background-color: ${colour.primary};
    height: 86px;
    padding: 0 30px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const Logo = styled.div`
    align-self: end;
`;

const LinksContainer = styled.ul`
    display: flex;
    flex-direction: row;
    gap: 22px;

    padding: 0;
    list-style: none;
`;

const CurrentQuizName = styled.div`
    font-family: 'dumbledor__kernedregular';
    color: #D1BB87;
`

const LinkItem = styled.a`
`;


export default function Header() {
    const location = useLocation();

    let quizName = '';

    try {
        const pathName = location.pathname[0] === '/' ? location.pathname.substr(1) : location.pathname;
        const pathSplit = pathName.split('/');

        quizName = getQuizName(pathSplit[0]);
    }
    catch(e) {

    }

    return (
        <Main>
            <Logo><Link to="/"><img src="/img/global/logo_text.webp" width="330" height="75" alt="Blood on the Clocktower logo" /></Link></Logo>
            <CurrentQuizName>{quizName}</CurrentQuizName>
            <LinksContainer>
                <LinkItem href="https://bloodontheclocktower.com/"><img src="/img/global/icon_home.webp" alt="Home icon" width="22" height="22" /></LinkItem>
                <LinkItem href="https://www.facebook.com/BloodontheClocktower/"><img src="/img/global/icon_facebook.webp" alt="Facebook logo" width="22" height="22" /></LinkItem>
                <LinkItem href="https://www.instagram.com/bloodontheclocktower/"><img src="/img/global/icon_instagram.webp" alt="Instagram logo" width="22" height="22" /></LinkItem>
                <LinkItem href="https://www.twitch.tv/thepandemoniuminstitute"><img src="/img/global/icon_twitch.webp" alt="Twitter logo" width="22" height="22" /></LinkItem>
                <LinkItem href="https://twitter.com/BloodClocktower"><img src="/img/global/icon_twitter.webp" alt="Twitter logo" width="22" height="22" /></LinkItem>
                <LinkItem href="https://www.youtube.com/bloodontheclocktower"><img src="/img/global/icon_youtube.webp" alt="YouTube logo" width="22" height="22" /></LinkItem>
            </LinksContainer>
        </Main>
    );
}
