import React from 'react';
import styled from 'styled-components';
import colour from '../../constants/colour';
import AnswerOption from './AnswerOption';
import QuestionNextLink from './QuestionNextLink';

const AnswerAreaComponent = styled.div`
    width: 100%;
    box-sizing: border-box;
    flex-grow: 1;

    display: flex;
    flex-direction: column;
    
    padding: 10px 20px 10px;
    text-align: left;
    color: #5C1F22;
`;

const AnswerListComponent = styled.ul`
    width: 100%;
    padding: 0;
    font-family: 'Trade Gothic LT Std';
`;

const SubTitle = styled.h2`
    text-align: center;
    padding-bottom: 5px;
    margin: 0 10px;

    font-family: 'dumbledor__kernedregular';
    font-size: 160%;
`;

const ResultHeader = styled.h2`
    color: ${props => props.isCorrect ? colour.success : colour.danger };
    margin: 2px 0 0;
    font-family: 'dumbledor__kernedregular';
`;

const ResultBody = styled.p`
    font-style: italic;
    font-family: 'Trade Gothic LT Std';
`;

const QuestionComponent = styled.div`
    margin: 0;
    font-family: 'Trade Gothic LT Std';
`;

const QuestionTextComponent = styled.h3`
    font-weight: normal;
    margin: 2px 0;
`;

const QuestionNextContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: auto;
`;

export default function AnswerArea({
    quizID,
    questionNum,
    questionCount,
    question,
    answersList,
    selectedAnswerIndex,
    handleAnswerSelect,
    correctAnswerIndex,
    answerReason,
}) {
    const isAnswered = selectedAnswerIndex > -1;
    const isCorrect = correctAnswerIndex === selectedAnswerIndex;

    let result;

    if (isAnswered) {
        result = (
            <React.Fragment>
                <ResultHeader isCorrect={isCorrect}>{isCorrect ? 'Correct!' : 'Incorrect'}</ResultHeader>
                <ResultBody>
                    {answerReason}
                </ResultBody>
            </React.Fragment>
        );
    }

    return (
        <AnswerAreaComponent>
            <SubTitle>Question {questionNum}/{questionCount}</SubTitle>
            <QuestionComponent>
                <QuestionTextComponent>{question}</QuestionTextComponent>
            </QuestionComponent>
            <AnswerListComponent>
                {
                    answersList.map(
                        (answer, i) =>
                            <AnswerOption
                                answerIndex={i}
                                key={i}
                                isAnswered={isAnswered}
                                isSelected={i === selectedAnswerIndex}
                                isCorrect={i === correctAnswerIndex}
                                handleAnswerSelect={() => handleAnswerSelect(i)}
                                answerText={answer}
                            />
                    )
                }
            </AnswerListComponent>
            {result}
            <QuestionNextContainer>
                <QuestionNextLink
                    isLightMode={true}
                    isEnabled={selectedAnswerIndex > -1}
                    quizID={quizID}
                    questionCount={questionCount}
                    questionNum={questionNum}
                />
            </QuestionNextContainer>
        </AnswerAreaComponent>
    );
}
