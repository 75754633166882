import 'whatwg-fetch';
import { fetchQuizData } from './fetchQuizData';

let quizData = {};

export async function getQuizData() {
    quizData = await fetchQuizData();
}

export function getQuizList() {
    return Object.fromEntries(
        Object.keys(quizData.quizzes)
            .map(quizID => [quizID, {
                label: quizData.quizzes[quizID].name,
                groupName: quizData.quizzes[quizID].groupName,
                difficulty: quizData.quizzes[quizID].difficulty,
                groupImageOverride: quizData.quizzes[quizID].groupImageOverride,
                groupInkOverride: quizData.quizzes[quizID].groupInkOverride,
                coverImage: quizData.quizzes[quizID].image
            }])
    );
}

export function getQuizName(quizID) {
    return quizData.quizzes[quizID].name;
}

export function getQuestionCount(quizID) {
    return quizData.quizzes[quizID].questions.length;
}

export function getAllQuestionsData(quizID) {
    return quizData.quizzes[quizID].questions;
}

export function getQuestionData(quizID, questionIndex) {
    return quizData.quizzes[quizID].questions[questionIndex];
}

export function getQuizResultText(quizID, correctAnswerCount) {
    const data = quizData.quizzes[quizID].resultText[correctAnswerCount];

    const resp = {};

    if (data)
    {
        resp.resultText = data.text;
        resp.resultImageFilename = data.imagePath.toLowerCase() + ".webp";
        resp.bgFilename = quizData.quizzes[quizID].groupName.toLowerCase().replace(/[^a-z]/g, '') + ".webp";
    }

    return resp;
}

const storageKeyAppPrefix = 'botcquiz_';

function createQuizAnswerStorageKey(quizID, questionIndex) {
    return `${createQuizAnswerPrefixStorageKey(quizID)}${questionIndex}`;
}

function createQuizAnswerPrefixStorageKey(quizID) {
    return `${storageKeyAppPrefix}answer_${quizID}_`;
}

export function clearQuizAnswers(quizID) {
    // iterating backwards because localStorage may be modified while iterating
    for (let i = localStorage.length - 1; i > -1; i--) {
        if (localStorage.key(i).indexOf(createQuizAnswerPrefixStorageKey(quizID)) === 0) {
            localStorage.removeItem(localStorage.key(i));
        }
    }
}

export function saveQuizAnswer(quizID, questionIndex, answerIndex) {
    localStorage.setItem(createQuizAnswerStorageKey(quizID, questionIndex), answerIndex);
}

export function getQuizAnswer(quizID, questionIndex) {
    const result = parseInt(localStorage.getItem(createQuizAnswerStorageKey(quizID, questionIndex)));
    return isNaN(result) ? -1 : result;
}

export function getQuizAllAnswers(quizID) {
    const answersList = [];

    for (let i = localStorage.length - 1; i > -1; i--) {
        if (localStorage.key(i).indexOf(createQuizAnswerPrefixStorageKey(quizID)) === 0) {
            const lastIndexOfSeparator = localStorage.key(i).lastIndexOf('_');
            const questionIndex = parseInt(localStorage.key(i).substring(lastIndexOfSeparator + 1));
            answersList[questionIndex] = parseInt(localStorage.getItem(localStorage.key(i)));
        }
    }

    return answersList;
}

export function getFirstUnansweredQuestionIndex(quizID) {
    for (let i = 0; i < getQuestionCount(quizID); i++) {
        let questionAnswer = localStorage.getItem(createQuizAnswerStorageKey(quizID, i));

        if (questionAnswer === null) {
            return i;
        }
    }

    return -1; // i.e. all questions answered
}
