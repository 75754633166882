import React from 'react';
import styled from 'styled-components';
import { getQuizList } from '../../utils';
import TransitionContainer from '../TransitionContainer';
import Logo from '../Logo';
import QuizLinks from './QuizLinks';


const HomeComponent = styled('div')`
    width: 100%;
    height: 100%;

    overflow: auto;

    text-align: center;
    background-image: url("${process.env.PUBLIC_URL}/img/global/bg_beige_textured.webp");
    background-size: 1440px;
    background-repeat: repeat-y;
    background-position-y: center;
`;

const LogoComponent = styled(Logo)`
    margin: 20px 0 10px;
    width: 136px;
    height: 104px;
`;

const SubtitleComponent = styled.h3`
    color: #7B5D4E;
    font-family: 'dumbledor__kernedregular';
    margin-bottom: 70px;
    margin-top: 0;
    font-size: 150%;
`;


export default function QuizSelect() {
    return (
        <TransitionContainer>
            <HomeComponent>
                <LogoComponent isLarge={true} />
                <SubtitleComponent>Select your quiz category</SubtitleComponent>
                <QuizLinks quizList={getQuizList()} />
            </HomeComponent>
        </TransitionContainer>
    );
}
