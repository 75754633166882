import React from 'react';
import { withRouter } from 'react-router-dom';
import {
    clearQuizAnswers,
    getQuestionCount,
    getQuestionData,
    getQuizAnswer,
    saveQuizAnswer,
} from '../../utils';
import AnswerArea from './AnswerArea';
import QuestionPageLayout from './QuestionPageLayout';


class Question extends React.Component {
    constructor(props) {
        super(props);

        const questionNum = parseInt(this.props.match.params.questionNum);

        if (questionNum === 1) {
            clearQuizAnswers(this.props.match.params.quizID);
        }

        this.state = {
            quizID: this.props.match.params.quizID,
            questionNum,
            selectedAnswerIndex: getQuizAnswer(this.props.match.params.quizID, questionNum - 1),
        };
    }

    componentDidUpdate(prevProps, prevState) {
        let { quizID, questionNum } = this.props.match.params;
        questionNum = parseInt(questionNum);

        if (this.state.quizID !== quizID || this.state.questionNum !== questionNum) {
            this.setState({
                quizID,
                questionNum,
                selectedAnswerIndex: getQuizAnswer(this.props.match.params.quizID, questionNum - 1)
            });
        }
    }

    handleAnswerSelect = (answerIndex) => {
        saveQuizAnswer(this.state.quizID, this.state.questionNum - 1, answerIndex);
        this.setState({ selectedAnswerIndex: answerIndex });
    }

    render() {
        const { quizID, questionNum } = this.state;

        const questionCount = getQuestionCount(quizID);
        const questionData = getQuestionData(quizID, questionNum - 1);

        return (
            <QuestionPageLayout playersData={questionData.players} bottomLinkLabel="Leave current quiz" bottomLinkTo="/select">
                <AnswerArea
                    quizID={quizID}
                    questionNum={questionNum}
                    questionCount={questionCount}
                    question={questionData.question}
                    answersList={questionData.answerOptions}
                    answerReason={questionData.answerReason}
                    handleAnswerSelect={this.handleAnswerSelect}
                    selectedAnswerIndex={this.state.selectedAnswerIndex}
                    correctAnswerIndex={questionData.correctAnswer}
                />
            </QuestionPageLayout>
        );
    }
}

export default withRouter(Question);
